import { Button, Divider, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";

type DropdownProps = {
  items: { label: string; value: string }[];
  selectedValue: string;
  onSelect: (value: string) => void;
  width?: string;
  variant?: string;
};

const Dropdown = ({ items, selectedValue, onSelect, width = "116px", variant = "solid" }: DropdownProps) => {
  const selectedItem = items.find(item => item.value === selectedValue)?.label || items[0].label;

  return (
    <Menu offset={[0, 4]}>
      {/* Adding the offset of [0, 4] to create 4px gap */}
      <MenuButton
        as={Button}
        variant={variant}
        width={width}
        height="40px"
        bg="#eaecf1"
        border="2px solid"
        borderColor="#eaecf1"
        borderRadius="4px"
        _hover={{ bg: "#eaecf1" }}
        p="8px 16px"
      >
        <Flex justifyContent="space-between" alignItems="center" fontStyle="label01">
          {selectedItem}
          <Image src="/images/Down.svg" alt="arrow" boxSize="24px" ml="16px" />
        </Flex>
      </MenuButton>
      <MenuList
        defaultChecked={false}
        minWidth={width}
        width={width}
        borderRadius="8px"
        bg="white"
        border="2px solid"
        boxShadow="0px 1px 2px 2px rgba(195, 195, 195, 0.10)"
        mt="4px"
        p={4}
        borderColor="#eaecf1"
      >
        {items.map((item, index) => (
          <>
            {index === 1 && <Divider borderWidth={"1.5px"} color="gray.300" my={4} />}
            <MenuItem
              key={item.value}
              h="40px"
              onClick={() => onSelect(item.value)}
              _hover={{ bg: "#d9d9d9" }}
              fontWeight="label01"
              borderColor="#eaecf1"
            >
              {item.label}
            </MenuItem>
          </>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
