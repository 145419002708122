"use client";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next, useTranslation as useTranslationOrg } from "react-i18next";

import { getOptions } from "./settings";

const runsOnServerSide = typeof window === "undefined";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(
          `./locales/{{lng}}/{{ns}}.json/${language}/card-tags,common,dashboard,detect,docu-mng,drawings,gallery,login,material,reports,site-log,write-report.json`
        ),
    ),
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path"],
    },
    preload: ["ko", "en"],
  });

export function useTranslation(lng: string, ns?: string, options?: any) {
  // TODO: options type definition
  const ret = useTranslationOrg(ns, options);
  // const { i18n } = ret;
  // if (runsOnServerSide && i18n.resolvedLanguage !== lng) {
  //   i18n.changeLanguage(lng);
  // } else {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     console.log(i18n.resolvedLanguage, lng);
  //     if (i18n.resolvedLanguage === lng) return;
  //     i18n.changeLanguage(lng);
  //   }, [lng, i18n]);
  // }
  return ret;
}
